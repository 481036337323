// eslint-disable-next-line import/prefer-default-export
export const initialRequestState = {
    id: '',
    name: '',
    phoneNumber: '',
    nrc: '',
    vehiclePlate: '',
    reason: '',
    business: '',
    state: '',
    userType: '',
    expiresAt: '',
    email: '',
    companyName: '',
    temperature: '',
    loaded: false,
    occursOn: [],
    visitationDate: new Date(),
    visitEndDate: null,
    startsAt: new Date(),
    endsAt: new Date(),
    isGuest: false,
    videoBlobId: '',
    frontImageBlobId: '',
    backImageBlobId: '',
    isLoading: false,
    isObservationOpen: false,
    status: 'pending',
    isEdit: false,
    observed: false,
    requestId: ''
}