
import React from 'react';
import PropTypes from 'prop-types'

const SOSIcon = ({setOpen}) => (
  <svg width={35} height={14} style={{marginBottom: 10}} onClick={()=>setOpen(true)}>
    <g fill="#C31515" className="emergency-call-icon">
      <path d="M5.006,10.4191267 L2.29270906,10.4191267 C1.54281156,10.4191267 0.936,11.001664 0.936,11.7215634 C0.936,12.4414627 1.54281156,13.024 2.29270906,13.024 L5.006,13.024 C7.25035062,13.024 9.076,11.2713821 9.076,9.11681221 C9.076,6.96224234 7.25035062,5.20962442 5.006,5.20962442 C4.25737437,5.20962442 3.64929094,4.62574404 3.64929094,3.90718779 C3.64929094,3.18863154 4.25737437,2.60475116 5.006,2.60475116 L7.71929094,2.60475116 C8.46918844,2.60475116 9.076,2.02221388 9.076,1.30231453 C9.076,0.58253728 8.46918844,0 7.71929094,0 L5.006,0 C2.76164937,0 0.936,1.75261792 0.936,3.90718779 C0.936,6.06175766 2.76164937,7.81437558 5.006,7.81437558 C5.75449844,7.81437558 6.36270906,8.39813386 6.36270906,9.11681221 C6.36258187,9.83536846 5.75449844,10.4191267 5.006,10.4191267 Z" id="Path" />
      <path d="M22.914,5.20962442 C22.1655016,5.20962442 21.5572909,4.62586614 21.5572909,3.90718779 C21.5572909,3.18850944 22.1655016,2.60475116 22.914,2.60475116 L25.6272909,2.60475116 C26.3771884,2.60475116 26.984,2.02221388 26.984,1.30231453 C26.984,0.58253728 26.3771884,0 25.6272909,0 L22.914,0 C20.6696494,0 18.844,1.75261792 18.844,3.90718779 C18.844,6.06175766 20.6696494,7.81437558 22.914,7.81437558 C23.6624984,7.81437558 24.2707091,8.39813386 24.2707091,9.11681221 C24.2707091,9.83536846 23.6626256,10.4192488 22.914,10.4192488 L20.2007091,10.4192488 C19.4508116,10.4191267 18.844,11.001664 18.844,11.7215634 C18.844,12.4414627 19.4508116,13.024 20.2007091,13.024 L22.914,13.024 C25.1583506,13.024 26.984,11.2713821 26.984,9.11681221 C26.984,6.96224234 25.1583506,5.20962442 22.914,5.20962442 Z" id="Path" />
      <path d="M14.774,13.024 C17.0183506,13.024 18.844,11.2713821 18.844,9.11681221 L18.844,3.90718779 C18.844,1.75261792 17.0183506,0 14.774,0 C12.5296494,0 10.704,1.75261792 10.704,3.90718779 L10.704,9.11681221 C10.704,11.2713821 12.5297766,13.024 14.774,13.024 Z M13.4174181,3.90718779 C13.4174181,3.18863154 14.0255016,2.60475116 14.7741272,2.60475116 C15.5226256,2.60475116 16.1307091,3.18863154 16.1307091,3.90718779 L16.1307091,9.11681221 C16.1307091,9.83536846 15.5226256,10.4192488 14.774,10.4192488 C14.0255016,10.4192488 13.4172909,9.83549056 13.4172909,9.11681221 L13.4172909,3.90718779 L13.4174181,3.90718779 Z" id="Shape" />
    </g>
  </svg>
  );

  SOSIcon.propTypes = {
    setOpen: PropTypes.func.isRequired,
  }

export default SOSIcon